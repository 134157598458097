import React, {ChangeEvent} from 'react'
import {FieldProps} from 'formik'
import {Field} from 'formik'
import {InputProps} from 'antd'
import fr from 'world_countries_lists/data/countries/fr/world.json'
import CountryPhoneInput, {ConfigProvider} from 'antd-country-phone-input'

import 'antd-country-phone-input/dist/index.css'
import {formatPhone} from '@libs/helpers/get-phone'

interface InputPhoneProps extends InputProps {
  name: string
  fast?: boolean
  onBlur?: (event: React.FocusEvent<HTMLInputElement> | string) => void
  onChange?: (event: ChangeEvent<HTMLInputElement> | string) => void
  value: string | undefined
}

const InputPhone = ({
  name,
  fast,
  onBlur: $onBlur,
  onChange: $onChange,
  ...restProps
}: InputPhoneProps) => {
  return (
    <Field name={name} fast={fast}>
      {({field: {onChange, onBlur, value}, form: {setFieldValue}}: FieldProps<string>) => {
        const phone = {short: 'FR', phone: value}
        return (
          <ConfigProvider locale={fr}>
            <CountryPhoneInput
              {...restProps}
              inline
              defaultValue={phone}
              value={phone}
              name={name}
              id={name}
              onChange={(v) => {
                const data = formatPhone(v, v.short)
                onChange(name)(data.phone || '')
                $onChange && $onChange(data.phone || '')
                setFieldValue(name, data.phone)
              }}
              onBlur={(event) => {
                onBlur(event)
                $onBlur && $onBlur(event)
              }}
            />
          </ConfigProvider>
        )
      }}
    </Field>
  )
}

InputPhone.displayName = 'InputPhone'

export default InputPhone

import {FieldInputType} from '@shared/interfaces'
import {FieldComponentFactory} from '../fields.t'
import ArrayInput from './array-input/array-input'
import CloudinaryInput from './cloudinary-input/cloudinary-input'
import CodeInput from './code-input/code-input'
import DateInput from './date-input/date-input'
import EntityPickerInput from './entity-picker'
import LocationInput from './location-input/location-input'
import PhoneInput from './phone-input/phone-input'
import PriceBundleInput from './price-bundle-input/price-bundle-input'
import RichInput from './rich-input'
import RichInlineInput from './rich-inline-input'
import SelectMultiSelectInput from './select-multiselect-input/select-multiselect-input'
import SubformArrayInput from './subform-array/subform-array-input'
import SubformInput from './subform-input/subform-input'
import SwitchCheckboxInput from './switch-checkbox-input/switch-checkbox-input'
import TextInput from './text-input/text-input'
import TimeInput from './time-input/time-input'
import LayoutInput from './layout-input'
import ColorInput from './color-input/color-input'
import IconInput from './icon-input/icon-input'
import FileUploadInput from './file-upload/file-upload'
import RadioBool from './radio-bool/radio-bool'
import WorkflowInput from './wokflow-input/workflow-input'
import TableInput from './table'

const Inputs: Record<FieldInputType, FieldComponentFactory> = {
  text: TextInput,
  textarea: TextInput,
  number: TextInput,
  email: TextInput,
  password: TextInput,
  url: TextInput,
  phone: PhoneInput,
  'price-bundle': PriceBundleInput,
  object: SubformInput,
  'array-object': SubformArrayInput,
  array: ArrayInput,
  time: TimeInput,
  'time-range': TimeInput,
  date: DateInput,
  'date-range': DateInput,
  datetime: DateInput,
  'datetime-range': DateInput,
  bool: SwitchCheckboxInput,
  select: SelectMultiSelectInput,
  multiselect: SelectMultiSelectInput,
  file: CloudinaryInput,
  entity: EntityPickerInput,
  location: LocationInput,
  code: CodeInput,
  json: CodeInput,
  rich: RichInput,
  'rich-inline': RichInlineInput,
  layout: LayoutInput,
  color: ColorInput,
  icon: IconInput,
  'file-upload': FileUploadInput,
  'radio-bool': RadioBool,
  workflow: WorkflowInput,
  table: TableInput
}

export default Inputs

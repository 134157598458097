import {atPath} from '@libs/utils'
import {useGetByIdQuery} from '@queries/domains'
import {Select as SelectDomain} from '@shared/interfaces'
import {useField} from 'formik'
import {Radio, Select} from 'antd'
import _ from 'lodash'
import React, {useMemo} from 'react'
import * as Yup from 'yup'
import {FieldComponentFactory, FieldComponentProps} from '../../fields.t'
import FormItem from '../form-item/form-item'

interface SelectInputComponentProps {
  key: string
  label: string
  name: string
  type: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  options?: string[] | string
  isMulti?: boolean
  radio?: boolean
}

interface AntdOptionData {
  value: string
  label: string
}

function extractOptions(obj: SelectDomain | undefined): AntdOptionData[] {
  const options = obj?.options ?? []

  return options.map((option) => ({
    value: option.key,
    label: option.value
  }))
}

const SelectInputComponent: React.FC<FieldComponentProps<SelectInputComponentProps>> = ({
  field
}) => {
  const {label, ...props} = field

  const [formikField] = useField<string>({...props})

  const opt: string = field.options as string

  const {data, isLoading, isError} = useGetByIdQuery<SelectDomain>('selects', opt, {
    query: {enabled: !!opt}
  })

  const isMulti = field.type === 'multiselect'
  const options = extractOptions(data)
  const radio = !!field.radio && options.length < 5

  /**
   * Adapt the expected format to be compatible with Formik (lib compatibility)
   * @param value the value to adapt
   */
  const onChangeMulti = (value: string | string[] = '') => {
    formikField.onChange({target: {name: formikField.name, value}})
  }

  const selectedValue = useMemo(
    () => options.find(({value}) => formikField.value === value),
    [options, formikField.value]
  )

  const selectedValues = useMemo(
    () =>
      Array.isArray(formikField.value)
        ? options.filter(({value}) => value && formikField.value.includes(value))
        : [],
    [options, formikField.value]
  )

  return (
    <FormItem field={field} style={{flex: 1}} className='mb-2'>
      {radio ? (
        <Radio.Group
          name={formikField.name}
          onChange={formikField.onChange}
          className='w-100'
          value={formikField.value?.toString()}
          buttonStyle='solid'
          disabled={field.disabled}>
          {options.map((option) => (
            <Radio.Button key={option.value} value={option.value?.toString()}>
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      ) : (
        <Select
          value={
            // if isMulti and there are no values, set an empty array to avoid a warning
            isMulti ? (Array.isArray(selectedValues) ? selectedValues : []) : selectedValue
          }
          labelInValue
          showSearch
          mode={isMulti ? 'tags' : undefined}
          onChange={(value) => {
            if (isMulti && Array.isArray(value)) {
              onChangeMulti(value.map((v) => v.value).filter(Boolean))
            } else if (!Array.isArray(value)) {
              formikField.onChange({target: {name: formikField.name, value: value?.value}})
            }
          }}
          className='w-100'
          allowClear={!props.required}
          options={options}
          disabled={field.disabled}>
          {isLoading && <Select.Option value='loading'>Chargement...</Select.Option>}
          {isError && <Select.Option value='loading'>Erreur</Select.Option>}
        </Select>
      )}
      {/* Hidden span to trigger formik rerender (otherwise the displayed value is not updated when removed) */}
      <span hidden>{formikField.value}</span>
    </FormItem>
  )
}

const SelectInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.type === 'multiselect' ? [] : field.defaultValue
    },
    validationSchema() {
      if (field.type === 'multiselect' && field.required) {
        return {[field.key]: Yup.array().of(Yup.string()).min(1).required()}
      }

      const schema = !(field.type === 'multiselect') ? Yup.string() : Yup.array()

      return {[field.key]: field.required ? schema.required() : schema.nullable()}
    },
    generateComponent() {
      return <SelectInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default SelectInput

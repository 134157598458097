import {FieldComponent, FieldWrapper} from './fields/fields.t'
import _ from 'lodash'
import {setPath} from '@libs/utils'
import {FieldDomain, FormDomain} from '@shared/interfaces'
import Inputs from './fields/inputs/inputs'
import Widgets from './fields/widgets/widgets'
import {FormObject} from './form.t'
import {AnyMongoAbility} from '@casl/ability'

export const FieldFactory = (
  field: FieldDomain,
  forms: FormDomain[],
  ability?: AnyMongoAbility,
  formData?: FormObject
): FieldComponent | undefined => {
  try {
    switch (field.nature) {
      case 'input':
        return Inputs[field.type || 'text']?.(field, forms, undefined, ability, formData)
      case 'widget':
        return Widgets[field.widgetType]?.(field, forms, undefined, ability, formData)
      default:
        return undefined
    }
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export const initializeForm = (
  form: FormDomain,
  forms: FormDomain[],
  prefix = '',
  data: FormObject = {},
  ability?: AnyMongoAbility,
  domain?: string,
  forceDisabled = false
): FieldWrapper[] => {
  return form.fields.map((field) => {
    const newField = {...field}
    newField.name = prefix ? `${prefix}.${newField.key}` : newField.key
    newField.prefix = prefix

    const type = data.__type || domain

    if (ability && type && newField.nature === 'input') {
      const baseField = newField.name

      const fieldPermissions = {
        readField: newField.flat || ability.can('crud.read', data.__type ? data : type, baseField),
        updateField:
          newField.flat || ability.can('crud.update', data.__type ? data : type, baseField)
      }

      if (!fieldPermissions.readField) newField.hidden = true

      if ((!!data._id && !fieldPermissions.updateField) || forceDisabled) {
        newField.disabled = true
        newField.required = false
      }
    }

    return {
      domain: newField,
      component: FieldFactory(newField, forms, ability, data)
    }
  })
}

export const formInitalValues = (fields: FieldWrapper[], data?: FormObject): FormObject => {
  const components = fields.filter((field) => field.domain.nature === 'input')

  const componentsValues = components.reduce((acc, field) => {
    const initialValue = field.component?.initialValue?.(data)

    if (initialValue !== undefined) {
      if (
        field.domain.type === 'object' &&
        field.domain.flat === true &&
        typeof initialValue === 'object'
      ) {
        acc = _.merge(acc, initialValue)
      } else {
        setPath(acc, field.domain.key, initialValue)
      }
    }
    return acc
  }, {})

  return {...data, ...componentsValues}
}

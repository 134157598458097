import {DomainKey, DomainKeyPlural, Tab} from '@shared/interfaces'

export enum TabType {
  MAIN = 'main',
  SUB = 'sub',
  CUSTOM = 'custom'
}

export interface TabSummaryGeneric {
  key: DomainKey
  keyPlural: DomainKeyPlural
  name: string
  type: string
}

export type TabSummaryCustom = TabSummaryGeneric & Tab & {type: TabType.CUSTOM}
export type TabSummaryMainAndSub = TabSummaryGeneric & {type: TabType.MAIN | TabType.SUB}
export type TabSummary = TabSummaryMainAndSub | TabSummaryCustom

export interface DomainTabs {
  main: TabSummaryMainAndSub[]
  sub: TabSummaryMainAndSub[]
  mainAndSub: TabSummaryMainAndSub[]
  custom: TabSummaryCustom[]
  all: TabSummary[]
}

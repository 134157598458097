import * as utils from './utils'
import currency from 'currency.js'
import moment from 'moment-timezone'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const AsyncFunction = Object.getPrototypeOf(async function () {}).constructor

const Exec = async (code: string, params = {}) => {
  const libs = {
    currency,
    moment
  }
  const keys = [...Object.keys(params), ...Object.keys(libs)]
  const values = [...Object.values(params), ...Object.values(libs)]

  const fnc = new AsyncFunction(...keys, 'utils', 'libs', code)
  return fnc(...values, utils, libs)
}

export default Exec

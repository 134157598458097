import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useField, useFormikContext} from 'formik'

import {LookupItem, LookupOptions} from '@shared/erp-api'
import LookupTable from '@components/lookup-component/lookup-table'
import {InputProps, Menu, Select} from 'antd'
import {fetchGetByIdQuery, useGetByIdQuery} from '@queries'
import {BaseEntity, ErpDomain} from '@shared/interfaces'
import {useApp} from '@store/app'
import {PanelsKeys} from '@libs/panels/panels.t'
import {EditOutlined, PlusOutlined, SelectOutlined} from '@ant-design/icons'
import Exec from '@libs/exec'
import {FormObject} from '@components/forms/form.t'
import {useInmemoriServices} from '@services'
import {useQueryClient} from '@tanstack/react-query'
import Link from 'next/link'
import {setPath} from '@libs/utils'

interface EntityPickerSelectProps extends Omit<InputProps, 'value'> {
  name: string
  /** ERP Domain object - eg: {key: 'house', ...rest} */
  domain: ErpDomain
  /**
   * Field id
   * (A unique id used in order to generate unique table lookup cell id on a page with several entity pickers)
   */
  id: string
  /** Lookup options - used in order to fetch data */
  lookupOptions: LookupOptions
  /** On blur event callback */
  onBlur?: (event: React.FocusEvent<HTMLInputElement> | string | LookupItem) => void
  /** On change event callback */
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | string | LookupItem) => void
  /**
   * Is erp field disabled
   * default: false
   */
  disabled?: boolean
  /** Main entity that contains the context field */
  mainItem: BaseEntity
  /**
   * function that returns initial data on create
   */
  createContext?: string
  /**
   * {BaseEntity} main form values
   */
  values: BaseEntity
  /**
   * Dropdown items count
   * fallback value: 4
   */
  limit: number
  /**
   * Custom lookup query parameters
   */
  queryParams?: string
  /**
   * Hide CTAs in an EntityPicker
   * default: false
   */
  hideCTAs?: boolean
}

interface SelectOption {
  /** Select label (to be shown on the select view) */
  label?: string
  /** Select uniq key (id) */
  key?: string
  /** Select value - to be set as a field value */
  value?: string | LookupItem
}

/**
 * @typedef MenuActionKeys
 * @enum {string} - eg: add, edit
 */
type MenuActionKeys = 'add' | 'edit' | 'open'

const helpers = {
  formatValue(value?: string | LookupItem): string | undefined {
    return value && typeof value === 'object' ? value._id : value
  },
  formatLabel(value?: string | (LookupItem & {name?: string})): string | undefined {
    return typeof value === 'object' ? value.name || value._id : value
  }
}

// Initial page - initial value for state page
const INITIAL_PAGE = 1

const EntityPickerSelect: React.FC<EntityPickerSelectProps> = ({
  name,
  onChange: $onChange,
  domain,
  id,
  lookupOptions,
  mainItem,
  values,
  limit,
  queryParams,
  createContext = '',
  disabled = false,
  hideCTAs = false
}) => {
  const [search, setSearch] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)
  const [mouseLeft, setMouseLeft] = useState<boolean>()

  const [formField] = useField<LookupItem | string>(name)
  const formProps = useFormikContext<FormObject>()

  /** @param {LookupItem | string} value - current form value */
  const {value, onBlur} = formField

  // When the field is a reference (non hydrated id), we should fetch the entity
  const {
    data: defaultValue,
    isError,
    error
  } = useGetByIdQuery<LookupItem>(domain.keyPlural, helpers.formatValue(value) || '', {
    query: {
      enabled: Boolean(helpers.formatValue(value) || '')
    },
    api: {hydrate: true}
  })

  const {erpApiService} = useInmemoriServices()
  const queryClient = useQueryClient()

  // The selected item should be highlighted on the select dropdown
  const selectedLookupItem = useMemo<LookupItem | undefined>(
    (): LookupItem | undefined => (typeof value === 'object' ? value : defaultValue),
    [defaultValue, value]
  )

  useEffect(() => {
    if (selectedLookupItem) {
      formProps.setFieldValue(name, selectedLookupItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, selectedLookupItem])

  // Select dropdown custom component
  const dropdownRender = useCallback(
    (onItemClick: (record: LookupItem) => void) => {
      const Dropdown = () => {
        const {ability} = useApp()
        const [page, setPage] = useState(INITIAL_PAGE)

        const {Panels, t, api} = useApp()

        const valuesRef = useRef<BaseEntity>()
        valuesRef.current = values

        const searchRef = useRef<string>()
        searchRef.current = search

        const getCreateContext = useCallback(async () => {
          let data = {}
          if (createContext) {
            try {
              const ret = await Exec(createContext, {
                values: valuesRef.current,
                search: searchRef.current || '',
                api
              })
              if (ret) data = ret
            } catch (e) {
              console.warn('Create Context Function error', e)
            }
          }

          return data
        }, [api])

        /**
         * @function getActionCallback - returns the function that should be called on add or edit action
         *
         * @param {MenuActionKeys} key
         *
         * @returns {function} - action callback factory
         *
         * on edit => opens form edit panel with the suitable data
         * on add => opens form add panel with the suitable data
         *
         */
        const onMenuItemClick = useCallback(
          async (key: MenuActionKeys) => {
            if (key === 'open') {
              setOpen(false)
              return
            }

            switch (key) {
              case 'add': {
                const contextData = await getCreateContext()
                Panels.show(PanelsKeys.FORM_ADD, {
                  domain,
                  data: {
                    ctx: valuesRef.current,
                    ...contextData
                  },
                  onSave: (newValue: FormObject) => {
                    formProps.setFieldValue(name, newValue)
                    setOpen(false)
                  }
                })
                break
              }
              case 'edit': {
                const data =
                  typeof value === 'object'
                    ? await fetchGetByIdQuery(
                        domain.keyPlural,
                        value._id,
                        erpApiService,
                        queryClient,
                        {
                          api: {hydrate: true}
                        }
                      )
                    : defaultValue

                Panels.show(PanelsKeys.FORM_EDIT, {domain, data})
                break
              }
            }

            // remove focus from select input
            setTimeout(() => {
              document.getElementById(name)?.blur()
            }, 0)

            setOpen(false)
          },
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [Panels, value]
        )

        const menuItems = useMemo(() => {
          const items = []

          if (ability?.can('crud.create', domain.key)) {
            items.push(
              <Menu.Item key={'add'} icon={<PlusOutlined />} className='entity-picker-menu-item'>
                <a style={{fontSize: '11px', verticalAlign: 'top'}}>
                  {t('components.forms.fields.entityPicker.add')} {domain.name}
                </a>
              </Menu.Item>
            )
          }

          if (value) {
            if (value instanceof String) {
              if (ability?.can('crud.update', domain.key)) {
                items.push(
                  <Menu.Item
                    key={'edit'}
                    icon={<EditOutlined />}
                    className='entity-picker-menu-item'>
                    <a style={{fontSize: '11px', verticalAlign: 'top'}}>
                      {t('components.forms.fields.entityPicker.edit')} {domain.name}
                    </a>
                  </Menu.Item>
                )
              }

              if (ability?.can('crud.access', domain.key)) {
                items.push(
                  <Menu.Item
                    icon={<SelectOutlined />}
                    key='open'
                    className='entity-picker-menu-item'>
                    <Link
                      style={{fontSize: '11px', verticalAlign: 'top'}}
                      rel='noreferrer'
                      target='_blank'
                      href={`/domain/${domain.keyPlural}/${value}`}>
                      {t('components.forms.fields.entityPicker.open')} {domain.name}
                    </Link>
                  </Menu.Item>
                )
              }
            } else if (value instanceof Object) {
              if (ability?.can('crud.update', value)) {
                items.push(
                  <Menu.Item
                    key={'edit'}
                    icon={<EditOutlined />}
                    className='entity-picker-menu-item'>
                    <a style={{fontSize: '11px', verticalAlign: 'top'}}>
                      {t('components.forms.fields.entityPicker.edit')} {domain.name}
                    </a>
                  </Menu.Item>
                )
              }

              if (ability?.can('crud.access', value) && ability?.can('crud.read', value)) {
                items.push(
                  <Menu.Item
                    icon={<SelectOutlined />}
                    key='open'
                    className='entity-picker-menu-item'>
                    <Link
                      style={{fontSize: '11px', verticalAlign: 'top'}}
                      rel='noreferrer'
                      target='_blank'
                      href={`/domain/${domain.keyPlural}/${value._id}`}>
                      {t('components.forms.fields.entityPicker.open')} {domain.name}
                    </Link>
                  </Menu.Item>
                )
              }
            }
          }

          return items
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ability, t, value])

        if (isError) {
          return <div>{JSON.stringify(error)}</div>
        }

        return (
          <div style={{padding: '0'}}>
            {!hideCTAs && !!menuItems.length && (
              <Menu onClick={({key}) => onMenuItemClick(key as MenuActionKeys)}>
                {...menuItems}
              </Menu>
            )}
            <LookupTable
              domainName={domain.keyPlural}
              id={id}
              lookupOptions={{...lookupOptions, search}}
              limit={limit}
              queryParams={queryParams}
              onItemClick={onItemClick}
              page={page}
              setPage={setPage}
              selectedLookupItem={selectedLookupItem}
              updateStateManager={false}
              compact
            />
          </div>
        )
      }

      return Dropdown
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      domain,
      id,
      lookupOptions,
      search,
      error,
      isError,
      selectedLookupItem,
      mainItem,
      defaultValue,
      value,
      name
    ]
  )

  // Format the selected item to this pattern {label, item, value}
  const formattedValue = useMemo<SelectOption>((): SelectOption => {
    const $value = value && typeof value === 'object' ? value : defaultValue
    return {
      value: $value,
      key: $value?._id,
      label: $value?.displayValues?.[0] || helpers.formatLabel($value) || ''
    }
  }, [defaultValue, value])

  return (
    <Select
      showSearch
      labelInValue
      allowClear={Boolean(value || defaultValue)}
      open={open}
      disabled={disabled}
      dropdownRender={dropdownRender((record) => {
        formProps.setFieldValue(name, record)
        setOpen(false)
      })}
      onSearch={(v) => {
        if (v.length > 2 || !v.length) {
          setSearch(v)
        }
      }}
      id={name}
      value={formattedValue || ''}
      onChange={(v) => {
        if (v?.value) {
          formProps.setFieldValue(name, v)
          $onChange && $onChange(v?.value)
        }
      }}
      onBlur={(event) => {
        onBlur?.(event)
        if (mouseLeft) {
          setOpen(false)
        }
      }}
      onClear={() => {
        const newValues = {...formProps.values}
        setPath(newValues, name, undefined)
        formProps.setValues(newValues)
      }}
      onDropdownVisibleChange={() => {
        // toggle the open state on dropdown visible change
        setOpen(!open)
      }}
      onMouseEnter={() => {
        mouseLeft && setMouseLeft(false)
      }}
      onMouseLeave={() => {
        !mouseLeft && setMouseLeft(true)
      }}
      style={{width: '100%'}}
    />
  )
}

EntityPickerSelect.displayName = 'EntityPicker'

export default EntityPickerSelect

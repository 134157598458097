import env from '@beam-australia/react-env'
import {AxiosError} from 'axios'
import {NextPageWithLayout} from '@layouts/layout'
import {useI18n} from '@libs/i18n'
import PanelsWrapper, {usePanels} from '@libs/panels/panels'
import useToast from '@libs/toast'
import {InmemoriServicesProvider} from '@services'
import {AppContext, AppProvider} from '@store/app'
import antdConfig from '@styles/vendors/antdConfig'
import {Hydrate, QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {ConfigProvider, Modal, notification} from 'antd'
import frFR from 'antd/locale/fr_FR'
import {appWithTranslation} from 'next-i18next'
import type {AppProps} from 'next/app'
import Head from 'next/head'
import {default as React, ReactElement, useContext, useEffect} from 'react'
import 'src/styles/style.scss'
import initMonaco from '@libs/monaco'
import Script from 'next/script'
import initGoogleMaps from '@libs/google'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import {ErrorBoundary, WithRumComponentContext, useRumAction, useRumError} from '@libs/datadog'

import '@libs/datadog/rum'
import Helper from '@components/misc/helper'

const AUTH_SERVICE_URL = env('AUTH_SERVICE_URL') ?? ''
const ERP_API_SERVICE_URL = env('ERP_API_SERVICE_URL') ?? ''

if (!AUTH_SERVICE_URL) {
  console.error('Environment variable AUTH_SERVICE_URL is not defined')
}

if (!ERP_API_SERVICE_URL) {
  console.error('Environment variable ERP_API_SERVICE_URL is not defined')
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

moment.locale('fr')
moment.tz.setDefault('Europe/Paris')

const App = WithRumComponentContext(
  'AppComponent',
  ({Component, pageProps}: AppPropsWithLayout) => {
    const getLayout = Component.getLayout ?? ((page: ReactElement) => page)

    const handleStatusCode =
      (maxFailureCount: number) => (failureCount: number, error: unknown) => {
        if (error instanceof AxiosError && error?.response) {
          const doNotRetryStatusCodes = [400, 401, 403, 404, 500]
          return (
            !doNotRetryStatusCodes.includes(error.response.status) && failureCount < maxFailureCount
          )
        }

        return failureCount < maxFailureCount
      }

    const [queryClient] = React.useState(
      () =>
        new QueryClient({
          defaultOptions: {
            queries: {
              retry: handleStatusCode(5),
              retryDelay: (failureCount) => Math.min(2000 * 2 ** failureCount, 30000)
            },
            mutations: {
              retry: handleStatusCode(3)
            }
          }
        })
    )

    const [notificationApi, notificationContextHolder] = notification.useNotification({
      bottom: 60
    })

    const [modal, modalContextHolder] = Modal.useModal()

    const Panels = usePanels()
    const i18n = useI18n()
    const toast = useToast(notificationApi, i18n.t)

    const ddAction = useRumAction('trackingAppAction')
    const ddError = useRumError()

    useEffect(() => {
      if (typeof window === 'undefined') return

      initMonaco()
      initGoogleMaps()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <Script strategy='beforeInteractive' src='/__ENV.js' />
        <Head>
          <title>ERP - Inmemori</title>
        </Head>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <ConfigProvider theme={antdConfig} locale={frFR}>
                <InmemoriServicesProvider
                  authServiceBaseUrl={AUTH_SERVICE_URL}
                  erpApiServiceBaseUrl={ERP_API_SERVICE_URL}>
                  <AppProvider
                    Panels={Panels}
                    modal={modal}
                    t={i18n.t}
                    toast={toast}
                    Datadog={{trackAction: ddAction, trackError: ddError}}>
                    {getLayout(
                      <AppWrapper>
                        <Component {...pageProps} />
                        <PanelsWrapper control={Panels} t={i18n.t} />
                        {notificationContextHolder}
                        {modalContextHolder}
                        <Helper />
                      </AppWrapper>
                    )}
                  </AppProvider>
                </InmemoriServicesProvider>
              </ConfigProvider>
            </Hydrate>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ErrorBoundary>
      </>
    )
  }
)

const AppWrapper = ({children}: React.PropsWithChildren) => {
  const app = useContext(AppContext)

  useEffect(() => {
    if (app) {
      window.app = app
      window.VM = app.VM
      window.Panels = app.Panels
      window.CRM = app.CRM
      window.runtimeSnippets = {}
    }
  }, [app])

  return <>{children}</>
}

export default appWithTranslation(App)

import {AnyMongoAbility} from '@casl/ability'
import {asArray} from '@libs/utils'
import {ErpDomain} from '@shared/interfaces'
import {
  DomainTabs,
  TabSummary,
  TabSummaryCustom,
  TabSummaryMainAndSub,
  TabType
} from 'src/interfaces/tabs.t'

const DomainHelper = (Domain: ErpDomain) => {
  Domain.relations = Domain.relations || []

  const getRelation = (domain: string) => Domain.relations.find((rel) => rel.key == domain)

  const getChildren = () =>
    Domain.relations
      .filter((rel) => rel.isChild)
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((rel) => rel.key)

  const getChildrenTabs = () =>
    Domain.relations
      .filter((rel) => rel.isChild && rel.sub)
      .sort((a, b) => (a.name > b.name ? 1 : -1))

  const getParents = () =>
    Domain.relations
      .filter((rel) => rel.isParent)
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((rel) => rel.key)

  const getRelations = () =>
    Domain.relations.filter((rel) => !rel.isChild && !rel.isParent).map((rel) => rel.key)

  const getActions = () => {
    return Domain.actions || []
  }

  const sortTabs = (tabs: TabSummary[], order?: string[]) => {
    const array = tabs

    if (!order || !order.length) return array

    const newOrder = asArray(order).map((v) => ({
      key: v.split(':')[0],
      index: parseInt(v.split(':')[1], 10)
    }))

    const findIndex = (sort: {key: string; index: number}[], key: string) =>
      sort.find((v) => v.key == key)

    return array.sort((a, b) => {
      const orderA = findIndex(newOrder, a.key)
      const orderB = findIndex(newOrder, b.key)

      if (!orderA && !orderB) return 0
      if (!orderA) return 1
      if (!orderB) return -1
      return orderA.index - orderB.index
    })
  }

  const getTabs = (ability?: AnyMongoAbility): DomainTabs => {
    const main: TabSummaryMainAndSub[] = [
      {key: Domain.key, keyPlural: Domain.keyPlural, name: Domain.name, type: TabType.MAIN}
    ]
    const sub = getChildrenTabs()
      .map((v) => {
        if (ability?.cannot('crud.access', v.key)) return

        return {
          key: v.key,
          keyPlural: v.keyPlural,
          name: v.namePlural || v.name,
          type: TabType.SUB
        }
      })
      .filter(Boolean) as TabSummaryMainAndSub[]
    const custom = (Domain.tabs || [])
      .map((tab) => {
        if (ability?.cannot(`tab.${tab._id}`, Domain.key) && ability?.cannot('tab.all', Domain.key))
          return

        return {
          ...tab,
          keyPlural: `${tab.key}s`,
          type: TabType.CUSTOM
        }
      })
      .filter(Boolean) as TabSummaryCustom[]

    return {
      main,
      sub,
      mainAndSub: [...main, ...sub],
      custom,
      all: [...main, ...sortTabs([...sub, ...custom], Domain.tabOrder)]
    }
  }

  return {
    Domain,
    getChildren,
    getChildrenTabs,
    getParents,
    getRelation,
    getRelations,
    getActions,
    getTabs,
    relations: Domain.relations
  }
}

export default DomainHelper

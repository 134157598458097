/**
 * The goal is that OPS can, possibly, define a custom TTC that is saved in the DB, otherwise we have automatic calculation of HT and TTC.
 * The TVA can be fetched from a 'tvaPath' defined in ERP form or a select or from. If none of them are valid, we have a default fallback.
 * If tvaPath is defined, we hide the select and OPS can't change it.
 *
 * tvaValueFromPath can be 0 so don't use !tvaValueFromPath
 *
 * Don't forget to add a post-save in the API to update if you rely on the tvaPath
 *
 * note: <Space.compact won't work with InputNumber/Select from formik-antd.
 */

import * as Yup from 'yup'
import React, {useState, useEffect} from 'react'
import {atPath} from '@libs/utils'
import {Select, InputNumber} from 'antd'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {useField, useFormikContext} from 'formik'
import LinkButton from './link-button'
import _ from 'lodash'
import {useLookupQuery} from '@queries/domains'
import currency from 'currency.js'
import formatNumber from '@libs/helpers/numbers'
import FormItem from '../form-item/form-item'

interface PriceBundleInputComponent {
  key: string
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  tvaPath?: string // defined in ERP form
}

interface PriceBundleField {
  ht?: number
  ttc?: number
  tva: number
}

type SelectOptions = {
  key: string
  value: string | number
}

const PriceBundleInputComponent: React.FC<FieldComponentProps<PriceBundleInputComponent>> = ({
  field
}) => {
  const {label, tvaPath, ...props} = field
  const {setFieldValue, values} = useFormikContext()
  const [formikField, , helper] = useField<PriceBundleField>({...props})
  const [isAutomatic, setIsAutomatic] = useState(true)

  // on first render (same default values as in schema definition)
  useEffect(() => {
    if (!formikField.value) {
      const obj = {
        ht: 0,
        ttc: 0,
        tva: undefined
      }
      setFieldValue(formikField.name, obj)
    }
  }, [])

  const tvaValueFromPath =
    tvaPath && typeof tvaPath === 'string' ? _.get(values, tvaPath) : undefined

  // if there is no tva from path, get the list of TVA from the select or use a fallback
  const tvaSelect = '621e4ec6fda2c3573b2c3566'
  const fallbackTva = [0, 10, 20]
  const {data} = useLookupQuery('selects', {
    limit: 1,
    q: {
      _id: tvaSelect
    }
  })
  const tvaConfiguration: number[] = Array.isArray(data?.data?.at(0)?.options)
    ? (data?.data?.at(0)?.options as SelectOptions[])?.map((option: SelectOptions) =>
        parseInt(option.key)
      )
    : fallbackTva

  const tvaToUse = tvaValueFromPath !== undefined ? tvaValueFromPath : formikField.value?.tva

  const onHtChange = (value: any) => {
    // handle the delete case or if value is not a number
    if (!value || isNaN(Number(value))) {
      helper.setValue({...formikField.value, ht: 0, ttc: 0})
      return
    }

    if (typeof tvaToUse === 'undefined' || typeof value !== 'number' || isAutomatic === false)
      return
    const tva = tvaToUse
    const ttc = value

    const taxes = currency(ttc, {precision: 6}).multiply(tva / 100).value
    const newTtc = currency(ttc, {precision: 6}).add(taxes).value
    const precision2Ttc = tvaToUse === 0 ? value : parseFloat(newTtc.toFixed(2))

    helper.setValue({
      ...formikField.value,
      ht: value,
      ttc: precision2Ttc
    })
  }

  const onTtcChange = (value: unknown) => {
    // handle the delete case
    if (!value || isNaN(Number(value))) {
      helper.setValue({...formikField.value, ttc: undefined})
      return
    }

    if (typeof tvaToUse === 'undefined' || typeof value !== 'number' || isAutomatic === false)
      return

    const tva = tvaToUse
    const ht = value

    const taxes = currency(ht, {precision: 6}).multiply(tva / 100).value
    const newHt = currency(ht, {precision: 6}).subtract(taxes).value
    const precision2Ht = tvaToUse === 0 ? value : parseFloat(newHt.toFixed(2))

    helper.setValue({
      ...formikField.value,
      ht: precision2Ht,
      ttc: value
    })
  }

  const onTvaChange = (value: unknown) => {
    if (typeof value !== 'number') return

    const newTva = value
    const ht = formikField.value.ht || 0

    const taxes = currency(ht, {precision: 6}).multiply(newTva / 100).value
    const newTtc = currency(ht, {precision: 6}).add(taxes).value
    const precision2Ttc = parseFloat(newTtc.toFixed(2))

    helper.setValue({
      ...formikField.value,
      tva: value,
      ttc: precision2Ttc
    })
  }

  return (
    <FormItem field={field} hasFeedback={false}>
      <div style={{display: 'flex', alignItems: 'center', gap: '3px'}}>
        {tvaValueFromPath === undefined && (
          <Select
            style={{width: 80, flexShrink: 0}}
            options={
              tvaConfiguration &&
              tvaConfiguration.map((tva: number) => ({label: `${tva} %`, value: tva}))
            }
            value={formikField.value?.tva}
            onChange={onTvaChange}
            placeholder='TVA'
          />
        )}
        <InputNumber
          name={`${props.name}.ht`}
          value={formikField.value?.ht}
          style={{width: 108}}
          onChange={onHtChange}
          decimalSeparator=','
          formatter={formatNumber}
          suffix='HT'
        />
        <InputNumber
          name={`${props.name}.ttc`}
          value={formikField.value?.ttc}
          style={{width: 108}}
          onChange={onTtcChange}
          decimalSeparator=','
          formatter={formatNumber}
          suffix='TTC'
        />
        <LinkButton isAutomatic={isAutomatic} setIsAutomatic={setIsAutomatic} />
      </div>
    </FormItem>
  )
}

const PriceBundleInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || null
    },
    validationSchema() {
      const schema = Yup.object().shape({
        tva: Yup.number(),
        ht: Yup.number(),
        ttc: Yup.number()
      })
      return {
        [field.key]: field.required ? schema.required() : schema.nullable()
      }
    },
    generateComponent() {
      return <PriceBundleInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default PriceBundleInput

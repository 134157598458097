import CustomTable from '@components/custom-table'
import usePrevious from '@libs/helpers/usePrevious'
import {useErpDomainsByKeyPluralQuery, useLookupQuery} from '@queries'
import {LookupItem, LookupOptions} from '@shared/erp-api'
import {useApp} from '@store/app'
import {Empty, TableColumnsType, TablePaginationConfig} from 'antd'
import {Dispatch, useEffect, useState} from 'react'
import LookupTableCell from './lookup-table-cell'
import {DomainKeyPlural, ErpDomain} from '@shared/interfaces'
import {ReplaceUrl, replaceUrl} from '@libs/router'

export interface LookupTableProps {
  domainName: DomainKeyPlural
  selectedLookupItem?: LookupItem
  setSelectedLookupItem?: Dispatch<LookupItem | undefined>
  lookupOptions?: LookupOptions
  page: number
  setPage: Dispatch<number>
  limit?: number
  queryParams?: string
  id?: string
  /** Can execute custom function on item click */
  onItemClick?: (record: LookupItem) => void
  /** compact mode for selects, modals, panels ... */
  compact?: boolean
  /** (default: true) autoselect first item on mount, page change, etc. */
  autoSelect?: boolean
  /** defines if the component should update the StateManager */
  updateStateManager?: boolean
  /** defines the main domain, if the current one is a sub domain */
  mainDomainName?: string
}

const DEFAULT_PAGE_SIZE = 15

const LookupTable = (props: LookupTableProps) => {
  const {
    domainName,
    selectedLookupItem,
    setSelectedLookupItem,
    lookupOptions = {},
    page,
    setPage,
    onItemClick,
    id,
    limit = DEFAULT_PAGE_SIZE,
    queryParams,
    compact = false,
    autoSelect = true,
    updateStateManager,
    mainDomainName
  } = props

  const [pageSize, setPageSize] = useState(limit)
  const [total, setTotal] = useState(1)
  const {t, stateManager, CRM, VM} = useApp()
  const [isLoaded, setIsLoaded] = useState(false)

  const {data, isLoading, isError, isSuccess} = useLookupQuery(
    domainName,
    {
      page,
      limit: pageSize,
      ...lookupOptions,
      ...(queryParams && {queryParams: JSON.parse(queryParams)})
    },
    {refetchOnReconnect: false, refetchOnWindowFocus: false}
  )

  const erpDomain = useErpDomainsByKeyPluralQuery(domainName)

  useEffect(() => {
    if (stateManager && updateStateManager && erpDomain.data) {
      stateManager.sub.setList(erpDomain.data.key, data?.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, domainName, erpDomain.data, updateStateManager])

  useEffect(() => {
    if (stateManager && updateStateManager && erpDomain.data) {
      stateManager.sub.setItem(erpDomain.data.key, selectedLookupItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, domainName, erpDomain.data, selectedLookupItem, updateStateManager])

  const prevName = usePrevious(domainName)
  const prevSearch = usePrevious(lookupOptions.search)

  useEffect(() => {
    if (prevName !== domainName && prevName) setPage(1)
  }, [domainName, prevName, setPage])
  useEffect(() => {
    if (prevSearch !== lookupOptions.search) setPage(1)
  }, [lookupOptions.search, prevSearch, setPage])

  useEffect(() => {
    if (
      autoSelect &&
      setSelectedLookupItem &&
      data &&
      (selectedLookupItem === undefined ||
        !data.data.find(({_id}) => selectedLookupItem?._id === _id)) &&
      isSuccess
    ) {
      setSelectedLookupItem(data.data[0])
    }
  }, [autoSelect, data, isSuccess, selectedLookupItem, setSelectedLookupItem])

  useEffect(() => {
    if (setSelectedLookupItem && data && selectedLookupItem && isSuccess) {
      const updatedVersion = data.data.find(({_id}) => selectedLookupItem._id === _id)
      if (updatedVersion) {
        setSelectedLookupItem(updatedVersion)

        if (mainDomainName && CRM) {
          const {key: mainDomainKey} = CRM.getDomain(mainDomainName) as ErpDomain

          const isCurrentlyMainDomain = VM?.tab === mainDomainKey

          const replaceCurrentUrl = (setSubDomain = false) => {
            const data: ReplaceUrl = {
              domain: mainDomainName,
              domainId: stateManager?.sub.getItem(mainDomainKey)?._id,
              subDomain: updatedVersion.__type,
              subDomainId: updatedVersion._id
            }

            if (!setSubDomain) {
              data.domainId = updatedVersion._id

              delete data.subDomain
              delete data.subDomainId
            }

            replaceUrl(data)
          }

          if (isCurrentlyMainDomain) {
            replaceCurrentUrl()
          } else {
            if (isLoaded) {
              replaceCurrentUrl(true)
            } else {
              // TODO: this is a hack to make sure the url is updated after the first render
              setTimeout(() => {
                replaceCurrentUrl(true)
              }, 200)
            }
          }
        }

        setIsLoaded(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess, selectedLookupItem, setSelectedLookupItem])

  useEffect(() => {
    if (data?.pager.total !== undefined && total !== data?.pager.total) {
      setTotal(data?.pager.total ?? 0)
    }
  }, [data?.pager.total, total])

  if (isError)
    return (
      <Empty
        style={{padding: '40px'}}
        description={t('components.lookup-component.lookup-table.an-error-happened')}
      />
    )

  const columns: TableColumnsType<LookupItem> = [
    {
      dataIndex: 'displayValues',
      render: (_: string[], record: LookupItem) => {
        return (
          <LookupTableCell lookupItem={record} compact={compact} key={record._id} id={props.id} />
        )
      }
    }
  ]

  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (autoSelect && page !== pagination.current && setSelectedLookupItem) {
      setSelectedLookupItem(undefined)
    }

    setPage(pagination.current || 1)
    setPageSize(pagination.pageSize || 1)
  }

  return (
    <CustomTable
      compact={compact}
      data={data?.data}
      columns={columns}
      pageSize={pageSize}
      total={total}
      page={page}
      isLoading={isLoading}
      handleTableChange={handleTableChange}
      id={id}
      onItemClick={onItemClick}
      setSelectedLookupItem={setSelectedLookupItem}
      selectedLookupItem={selectedLookupItem}
    />
  )
}

export default LookupTable

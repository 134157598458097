import * as Yup from 'yup'
import React, {useMemo} from 'react'
import {atPath} from '@libs/utils'
import {FormObject} from '@components/forms/form.t'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {useField, useFormikContext} from 'formik'
import _ from 'lodash'
import {ErpTable, FieldDomain} from '@shared/interfaces'
import Table from '@components/table'
import {useGetByIdQuery} from '@queries'
import {Crud} from '@components/table/table.t'
import FormItem from '../form-item/form-item'

interface TableInputComponentProps {
  _id: string
  key: string
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  table?: string
}

const TableInputComponent: React.FC<FieldComponentProps<TableInputComponentProps>> = ({field}) => {
  // Formik field
  const [formikField] = useField<FormObject[]>(field)

  const {setFieldValue} = useFormikContext()

  const {data: table} = useGetByIdQuery<ErpTable>('tables', field.table || '', {
    query: {
      enabled: Boolean(field.table)
    }
  })

  const crud = useMemo(
    (): Crud => ({
      onAdd(values: FormObject) {
        setFieldValue(field.name, [...formikField.value, values])
      },
      onEdit(values: FormObject) {
        setFieldValue(
          field.name,
          [...formikField.value].map((item) => (item._id === values._id ? values : item))
        )
      },
      onRemove(values: FormObject) {
        setFieldValue(
          field.name,
          [...formikField.value].filter((item) => item._id !== values._id)
        )
      }
    }),
    [field.name, formikField.value, setFieldValue]
  )

  // field's Form props
  return (
    <FormItem field={field} style={{flex: 1}} className='mb-2'>
      {table && <Table table={table} data={formikField.value} crud={crud} />}
    </FormItem>
  )
}

const TableInput: FieldComponentFactory = (field: FieldDomain) => {
  return {
    initialValue(data) {
      return data && atPath(data, field.key)
    },
    validationSchema() {
      const schema: Yup.MixedSchema<Yup.AnyObject | null | undefined> = Yup.mixed()

      return {[field.key]: field.required ? schema.required() : schema.nullable()}
    },
    generateComponent() {
      return <TableInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default TableInput

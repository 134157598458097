import React, {useMemo} from 'react'
import {useField, useFormikContext} from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import {atPath} from '@libs/utils'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'

import {LocationInputComponentProps, LocationInputOptions} from './location-input.t'
import PlaceLocationInput, {LocationObject} from '@components/misc/location-input'
import {Col, Input} from 'antd'
import resolveLabel from '@libs/helpers/resolve-label'

const LocationInputComponent: React.FC<FieldComponentProps<LocationInputComponentProps>> = ({
  field
}) => {
  const defaultForm = {
    zipcode: '',
    city: '',
    country: '',
    country_code: '',
    geo: undefined,
    place_id: '',
    region: '',
    state: '',
    street: '',
    street_number: '',
    address: '',
    fullAddress: '',
    additionalInfo: '',
    info: '',
    meta: {}
  }

  const [formikField] = useField<LocationObject>({...field})
  const {setFieldValue} = useFormikContext()

  const onAddressSelect = (address: LocationObject) => {
    if (address && address.geocode && Object.keys(address.geocode).length) {
      setFieldValue(field.name, {
        ...defaultForm,
        ...(address.geocode.address && {fullAddress: address.geocode.address}),
        ...address.geocode,
        ...(address.address && {address: address.address}),
        ...(address.latlng && {
          latlng: address.latlng
        }),
        info: formikField.value?.info
      })
    } else {
      setFieldValue(field.name, {
        address: address.address,
        info: formikField.value?.info
      })
    }
  }

  // updating only fullAddress without altering the entire object
  const onAddressChange = (fullAddress: string) => {
    setFieldValue(field.name, {
      ...formikField.value,
      fullAddress
    })
  }

  const options = useMemo(() => {
    const options: LocationInputOptions = {
      searchOptions: {}
    }

    if (Array.isArray(field.filters) && field.filters.length)
      options.searchOptions.types = field.filters
    if (Array.isArray(field.countries) && field.countries.filter(Boolean).length)
      options.searchOptions.componentRestrictions = {country: field.countries}

    return options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.name, formikField.value, field.countries, field.filters])

  const handleInfoChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldValue(field.name, {
      ...formikField.value,
      info: event.target.value
    })
  }

  return (
    <Col className='w-100 m-0 p-0'>
      <div className='ant-form-item-label'>{resolveLabel(field)}</div>
      <PlaceLocationInput
        value={
          typeof formikField.value === 'object' ? formikField.value?.fullAddress : formikField.value
        }
        onSelect={onAddressSelect}
        onChange={onAddressChange}
        placeholder={field.placeholder}
        disabled={field.disabled}
        {...options}
      />
      <Input.TextArea
        placeholder='Informations complémentaires'
        name='info'
        value={formikField.value.info}
        onChange={handleInfoChange}
        className='mt-2'
      />
    </Col>
  )
}

const LocationInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || ''
    },
    validationSchema() {
      let schema = Yup.object()
        .shape({
          address: Yup.string(),
          fullAddress: Yup.string(),
          city: Yup.string(),
          country: Yup.string(),
          place_id: Yup.string(),
          latlng: Yup.object().shape({
            lat: Yup.number(),
            lng: Yup.number(),
            string: Yup.string()
          }),
          geocode: Yup.mixed()
        })
        .nullable()

      if (field.required) schema = schema.required()

      return {[field.key]: schema}
    },
    generateComponent() {
      return <LocationInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default LocationInput
